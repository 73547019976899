import React from 'react'
import styled from "styled-components"
const directionRotate = {
    "down": 0,
    "up": 180,
    "left": 90,
    "right": -90,
}

const SvgRotated = styled.svg`
    transform: rotate(${({ direction = "down", deg = 0 }) => directionRotate[direction] + deg || 0}deg);
    transition: all 0.5s ease;
`

export const Chevron = ({ direction, ...rest }) => (
    <SvgRotated
        direction={direction}
        width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path d="M10 12L0 0L10 5L20 0L10 12Z" fill="black" />
    </SvgRotated>
)


export const Star = (props) => (
    <SvgRotated
        {...props}
        width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.432 0L17.4935 11.3705L28.8641 14.432L17.4935 17.4935L14.432 28.8641L11.3705 17.4935L0 14.432L11.3705 11.3705L14.432 0Z" fill="black" />
    </SvgRotated>
)


export const Email = (props) => (
    <svg width="110" height="120" viewBox="0 0 110 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M1.40918 43.8617C1.40918 40.6282 2.98682 37.5981 5.63578 35.7439L49.2721 5.1984C52.684 2.81007 57.2252 2.81007 60.6371 5.1984L104.273 35.7439C106.922 37.5981 108.5 40.6282 108.5 43.8617V109.091C108.5 114.564 104.064 119 98.591 119H11.3183C5.84564 119 1.40918 114.564 1.40918 109.091V43.8617Z" stroke="black" strokeWidth="2" />
        <path d="M21.1816 22.8178C21.1816 20.3577 23.176 18.3633 25.6362 18.3633H85.6362C88.0964 18.3633 90.0907 20.3576 90.0907 22.8178V59.1711L56.3057 87.6565L21.1816 59.1596V22.8178Z" fill="#F3CF21" stroke="black" strokeWidth="2" />
        <path d="M16.4316 106.363L38.9316 83.8633" stroke="black" strokeWidth="2" />
        <path d="M94.8408 106.363L72.3408 83.8633" stroke="black" strokeWidth="2" />
        <path d="M1.5 43.5L56.3179 87.9542L108.5 43.5" stroke="black" strokeWidth="2" />
        <path d="M40.6367 39.9551H53.2504" stroke="black" strokeWidth="2" stroke-linecap="round" />
        <path d="M40.6367 47.4082H69.614" stroke="black" strokeWidth="2" stroke-linecap="round" />
        <path d="M40.6367 54.8633H69.614" stroke="black" strokeWidth="2" stroke-linecap="round" />
    </svg>

)




export const CheckCircle = ({ checkColor = "white", circleColor = "black", big = false }) => big
    ? <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="28" cy="28" r="28" fill={circleColor} />
        <path d="M25.9 40.8337L14 26.5293L25.9 31.2974L43.75 13.417L25.9 40.8337Z" fill={checkColor} />
    </svg>
    : <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill={circleColor} />
        <path d="M15 21.5L10 15.5L15 17.5L22.5 10L15 21.5Z" fill={checkColor} stroke={checkColor} />
    </svg>


export const CrossCircle = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="#D67036" />
        <path d="M7.90147 7.90151L15.8029 13.5681L23.7044 7.90151L18.0378 15.803L23.7044 23.7044L15.8029 18.0379L7.90147 23.7044L13.5681 15.803L7.90147 7.90151Z" fill="black" />
    </svg>

)

export const Milestone = () => {

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ minWidth: 16 }}>
            <circle cx="8" cy="8" r="6" fill="white" stroke="#DBB98B" strokeWidth="4" />
        </svg>
    )
}
