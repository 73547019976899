import styled, { css } from "styled-components"

const headerStyles = css`
    font-family: TT Trailers;
    text-align: ${({ center }) => center ? "center" : "left"};
    ${({ compact }) => compact ? "margin: 0;" : null}
    ${({ underline }) => underline ? "text-decoration: underline;" : null}
    
`

export const H1 = styled.h1`
    ${headerStyles}
`

export const H2 = styled.h2`
    ${headerStyles}
    font-size: 48px;
    @media(max-width: 768px) {
        font-size: 32px;
    }
`

export const H3 = styled.h3`
    ${headerStyles}
    font-size: 32px;
`

export const H4 = styled.h4`
    ${headerStyles}
`

export const H5 = styled.h5`
    ${headerStyles}
`

export const Underline = styled.span`
    text-decoration: underline;
    cursor: ${({ onClick }) => onClick ? "pointer" : "auto"}
`

export const Center = styled.div`
    text-align: center;
`

export const Small = styled.span`
    font-size: 14px;
`
export const Bold = styled.span`
    font-weight: 700;
`

export const S = styled.span`
    font-weight: 700;
    color: ${({ light }) => light ? "rgba(0,0,0,.5)" : "inherit"};
    font-weight: ${({ bold }) => bold ? "700" : "inherit"};
    font-size: ${({ small }) => small ? "14px" : "inherit"};
    text-align: ${({ center }) => center ? "center" : "left"};
    line-height: ${({ tight }) => tight ? "20px" : "inherit"};
    ${({ underline }) => underline ? "text-decoration: underline;" : null}
    cursor: ${({ onClick }) => onClick ? "pointer" : "auto"}

`