import React from "react"
import styled from "styled-components"

export const StackS = styled.div`
    display: flex;
    
    flex-direction: ${({horizontal}) => horizontal? "row": "column"};
    align-items: ${({align}) => align || "stretch"};
`

const Item = styled.div`
    flex: 0 1 auto;
    margin: 0 ${({last, horizontal, gap}) => horizontal && !last ? `${gap}px` : "0" } ${({last, horizontal, gap}) => !horizontal && !last ? `${gap}px` : "0"} 0;

`

export const Stack = ({children, gap=16, horizontal=false}) => {
    return <StackS>
        {React.Children.map(children, (child, i) => child ? (
            <Item
                {...{ gap }}
                last={React.Children.count(children) === i + 1}
                horizontal={horizontal}
            >
                {child}
            </Item>
        ) : undefined)}

    </StackS>
}