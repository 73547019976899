import styled, { css } from "styled-components"


const buttonCss = css`
    border-radius: ${({ rounded }) => rounded ? "50px" : "16px"};
    padding: ${({ small }) => small ? "6px 14px" : "12px 24px"};
    font-size: ${({ small }) => small ? "14px" : "inherit"};
    background: ${({ outlined }) => outlined ? "none" : "#000"};
    border: ${({ outlined }) => outlined ? "2px solid rgba(0,0,0,.1)" : "none"};
    color: ${({ outlined }) => outlined ? "inherit" : "#fff"};
    text-align: center;
    display: inline-block;
    text-decoration: none;
    width: ${({ fluid }) => fluid ? "100%" : "auto"};
    cursor: ${({disabled}) => disabled ? "not-allowed" : "pointer"};
    opacity: ${({disabled}) => disabled ? 0.7 : "auto"} ;
`

export const Button = styled.button`
    ${buttonCss}
`

export const ButtonLink = styled.a`
    ${buttonCss}
`
