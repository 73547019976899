import * as React from "react"
import _ from "lodash"
import styled from "styled-components"

import { Chevron } from "/src/components/svgs"

const TextAreaStyled = styled.div`
border-radius: 16px;
box-sizing: border-box;

border: ${({ focus, hasError }) => hasError
        ? "1px solid #E51010"
        : focus
            ? "1px solid #000"
            : "none"};

background: #F5F5F5;
transition: all 0.5s ease;
overflow: hidden;
textarea {
    outline: none;
    resize: none;
    font-size: 16px;
    border: none;
    background: none;
    width: 100%;
    margin: 0;
    padding: ${({ focus, hasValue }) => focus || hasValue ? "0 16px 24px 16px" : "0px"};
    display: block;
    max-height: ${({ focus, hasValue, oneliner }) => focus || hasValue
        ? oneliner
            ? "3rem"
            : "20rem"
        : "0rem"};
    transition: max-height padding 0.5s ease;
}
label {
    padding: ${({ focus, hasValue }) => focus || hasValue ? "12px 16px 12px 16px" : "24px"};
    display: block;
    font-size: ${({ focus, hasValue }) => focus || hasValue ? 14 : 16}px;
    color: rgba(0,0,0,.5);
    transition: all 0.5s ease;
    cursor: pointer;
}
`
const Helper = styled.div`
    margin: -16px 0 16px 0;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: ${({ focus, hasError }) => focus
        ? hasError
            ? "16px"
            : "24px"
        : 0};
    max-height: ${({ focus }) => focus ? "15rem" : "0"};
    transition: all 0.5s ease;
    overflow: hidden;
    font-size: 14px;
`
const Error = styled.div`
color: #E51010;
font-size: 14px;
padding: 4px 16px;
`

const FieldContainer = styled.div`
background: ${({ focus }) => focus ? "#DEDEDE" : "#DEDEDE00"};
border-radius: 16px;
`

export const TextArea = ({ label, value, error, helper, oneliner, handleBlur, ...props }) => {
    const [focus, setFocus] = React.useState()
    const ref = React.useRef()

    React.useEffect(() => {
        ref.current.style.height = "0px"
        const scrollHeight = ref.current.scrollHeight
        ref.current.style.height = scrollHeight > 48 ? scrollHeight + "px" : "48px"
    }, [value]);


    return (
        <FieldContainer {...{
            focus,
            onClick: () => { ref.current.focus() }
        }}>
            <TextAreaStyled {...{ focus, oneliner, hasValue: !!value, hasError: !!error }}>
                <label htmlFor="lastName">{label}</label>
                <textarea
                    {
                    ...{
                        ...props,
                        value: value || "",
                        onFocus: (e) => {
                            setFocus(true)
                        },
                        onBlur: (e) => {
                            _.isFunction(handleBlur) && handleBlur(e)
                            _.delay(function (text) {
                                setFocus(false)
                            }, 200, 'later');
                        },
                    }
                    }
                    ref={ref}
                />
            </TextAreaStyled>
            {error ? <Error>{error}</Error> : undefined}
            {helper
                ? <Helper {...{ focus, hasError: !!error, onMouseDown: (e) => e.preventDefault() }}>
                    {helper}
                </Helper>
                : undefined}
        </FieldContainer>
    )
}


const SelectContainer = styled.div`

    background: #F5F5F5;
    border: 0;
    border-radius: 16px;
    position: relative;
    label {
        padding: ${({ focus, hasValue }) => focus || hasValue ? "12px 24px 0px 24px" : "24px"};
        display: block;
        font-size: ${({ focus, hasValue }) => focus || hasValue ? 14 : 16}px;
        color: rgba(0,0,0,.5);
        transition: all 0.5s ease;
        cursor: pointer;
    }
    .value{
        margin: 0 24px ${({ focus, hasValue }) => focus || hasValue ? "12px" : "0"} 24px;
    }
`

const SelectField = styled.select`
    padding: 0 24px;
    height: 100%;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    opacity: 0;

`

export const Select = ({ options = [], label, value, error, helper, handleBlur, ...props }) => {
    const [focus, setFocus] = React.useState()
    const ref = React.useRef()
    return (
        <FieldContainer {...{
            focus,
            onClick: () => { ref.current.focus() }
        }}>
            <SelectContainer {...{ focus, hasValue: !!value }} onClick={() => { ref.current.focus() }}>
                <div style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <div>

                        <label>{label}</label>
                        <div className="value">{value}</div>
                    </div>
                    <div style={{
                        padding: "12px"
                    }}>
                        <Chevron {...{ width: 18, height: 10, direction: focus ? "up" : "down" }} />
                    </div>
                </div>

                <div style={{ position: "absolute", width: "100%", top: 0, bottom: 0, height: "100%" }}>

                    <SelectField {...{
                        value,
                        ref,
                        ...props,
                        onFocus: () => { setFocus(true) },
                        onBlur: (e) => {
                            _.isFunction(handleBlur) && handleBlur(e)
                            _.delay(() => {
                                setFocus(false)
                            }, 200, 'later');
                        },
                    }}
                    >
                        <option value=""></option>
                        {options.map(({ text, value }) => {
                            return (
                                <option value={value}>{text}</option>
                            )
                        })}
                    </SelectField>
                </div>
            </SelectContainer>
            {error ? <Error>{error}</Error> : undefined}
        </FieldContainer>
    )
}




const CheckboxFieldContainer = styled.div`
    display: flex;
    background: #fff;
    padding: 16px;
    gap: 16px;
    border-radius: 16px;
    label {
        color: rgba(0,0,0,.5);
    }
    input[type="checkbox"] {
        height: 24px;
        width: 24px;
        appearance: none;
        border: 1px solid rgba(0,0,0,.5);
        border-radius: 8px;
    }
    input[type="checkbox"]:checked {
        background: #000;
        background: center no-repeat url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 12.5L1 6.5L6 8.5L13.5 1L6 12.5Z" fill="white" stroke="white"/></svg>') #000;
    }
`

export const Checkbox = ({ label, ...props }) => {
    console.log(props)
    return (
        <CheckboxFieldContainer>
            <div>
                <input type="checkbox" {...props} defaultChecked={props.value} checked={props.value} />
            </div>
            <label>
                {label}
            </label>
        </CheckboxFieldContainer>
    )
}