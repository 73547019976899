import * as React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { TextArea } from "./fields"
import { Button } from "./button"
import { Stack } from "/src/components/grid"
import { gql, useMutation, useQuery } from '@apollo/client';

import { Star, Email } from "/src/components/svgs"
import * as Text from "/src/components/text"

import styled from "styled-components"

import { useWindowSize } from "/src/hooks"

import { Chevron } from "/src/components/svgs"
import { trackEvent } from "/src/utils"

const ME = gql`
    query{
        me{
            email
        }
    }
`
const LOGIN = gql`
    mutation($email: String!){
            requestLogin(email: $email){
            emailSent
        }
    }
`

const validateEmail = (email = "") => {
    return email.toLowerCase().match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const LoginSignup = ({ initialIsSignup = true }) => {

    const [isSuccess, setIsSuccess] = React.useState(false)
    const [email, setEmail] = React.useState("")
    const [isSignup, setIsSignup] = React.useState(initialIsSignup)
    const [login] = useMutation(LOGIN, {
        onCompleted: (data) => {
            if (_.get(data, "requestLogin.emailSent")) {
                setIsSuccess(true)
                if(isSignup){
                    trackEvent("signup")
                }
            }
        }
    })

    const hasError = email && !validateEmail(email)

    if (isSuccess) return (
        <div style={{ margin: "60px 0" }}>

            <Stack gap={24}>
                <Email style={{ margin: "0 auto", display: "block" }} />
                <Text.H1 center compact>Перевірте пошту</Text.H1>
                <Text.Center>{`У вас вхідний на ${email}. Перейдіть за посиланням, щоб ${isSignup ? "завершити реєстрацію" : "увійти"}.`} </Text.Center>
                <Text.Center>
                    <Text.S light>Ввели неправильну електронну пошту? <Text.Underline onClick={() => setIsSuccess(false)}>Виправити</Text.Underline></Text.S>
                </Text.Center>
            </Stack>
        </div>

    )
    return <Stack gap={24}>
        <Text.H1 center>{isSignup ? "Реєстрація" : "Вхід"}</Text.H1>

        {isSignup ? (
            <div>
                <Text.S center light>
                    <p>
                        Щоб подати заявку, потрібно зареєструватися на сайті. Так ми зможемо зберегти прогрес, якщо ви вирішите закрити вкладку та закінчити пізніше.
                    </p>
                    <p>
                        Ми надішлемо результати відбору на вашу електронну пошту. Зазначте скриньку, яку ви регулярно перевіряєте.
                    </p>
                </Text.S>
            </div>
        ) : <div></div>}
        <TextArea
            label="Ваш e-mail"
            oneliner
            name="email"
            onChange={({ target: { value } }) => setEmail(value.trim())}
            value={email}
            error={hasError ? "Некоректна електронна пошта" : undefined}
        />
        <Button
            onClick={() => login({
                variables: {
                    email
                }
            })}
            fluid
            disabled={hasError || !email}
        >{isSignup ? "Зареєструватись" : "Увійти"}</Button>

        <div>
            {isSignup
                ? <Text.Center>Вже є акаунт? <Text.Underline onClick={() => setIsSignup(false)}>Увійдіть</Text.Underline></Text.Center>
                : <Text.Center>Немає акаунту? <Text.Underline onClick={() => setIsSignup(true)}>Зареєструйтесь</Text.Underline></Text.Center>
            }
        </div>
    </Stack>
}
const HeaderContainer = styled.div`
    position:fixed;
    width: 100%;
    left: auto;
    right: auto;
    background: #fff;
    z-index:10;
`
const HeaderStyled = styled.header`
    margin: 0 auto;
    max-width: 1280px;
    padding: 0 24px;
    border-bottom: rgba(165, 122, 89, 0.2) solid 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media(max-width: 768px) {
        padding: 0 16px;
    }
    @media(max-width: 1279px) {
        max-width: 1024px;
    }
`

const LogoS = styled.div`
    display: flex;
    color: black;
    text-decoration: none;
    font-family: Gilroy;
    font-size: 13px;
    line-height: 1.2;
    position: relative;
    .text{
        position: relative;
        z-index: 2;
        margin-left: 8px;
    }
`

export const Logo = () => (
    <LogoS>
        <div>
            <svg width="12" height="28" viewBox="0 0 12 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0H12V14H0V0Z" fill="#3875FF" />
                <path d="M0 14H12V28H0V14Z" fill="#F3CF21" />
            </svg>

        </div>

        <div className="text">
            <a href="/" style={{ textDecoration: "none", color: "#000" }}>
                <div>

                    Made in UA
                </div>
                <div style={{ color: "#808080" }}>

                    Support Project
                </div>
            </a>
        </div>
    </LogoS>

)


const UserDropdown = ({ me }) => {
    const { width } = useWindowSize()
    const [open, setOpen] = React.useState(false)
    const isMobile = width < 512
    return (
        <div>
            <Text.S small onClick={() => setOpen(!open)}>
                {isMobile ? _.truncate(me, { length: 17 }) : me}
                &nbsp;&nbsp;
                <Chevron {...{ width: 18, height: 10, direction: open ? "up" : "down" }} />
            </Text.S>
            <div>
            </div>
            <div style={{ position: "relative" }}>

                {open && <div style={{
                    position: "absolute",
                    background: "#fff",
                    borderRadius: 16,
                    width: "200px",
                    right: 0,
                    padding: "8px 16px",
                    textAlign: "right",
                    boxShadow: "rgba(0, 0, 0, 10%) 0 0 16px",
                    zIndex: 6

                }}>
                    <div>

                        <a href="/api/logout/" style={{
                            textDecoration: "none",
                            color: "#000",
                            fontSize: 14
                        }}>
                            Вийти
                        </a>
                    </div>
                </div>}
            </div>
        </div>
    )
}



const Header = ({ withMenu, onCta }) => {
    const { data: dataMe } = useQuery(ME)
    const { width } = useWindowSize()
    const [isLoginModalOn, setIsLoginModalOn] = React.useState(false)
    const [initialIsSignup, setInitialIsSignup] = React.useState(true)
    const me = _.get(dataMe, "me.email")
    return (
        <HeaderContainer>
            <HeaderStyled>
                <h1 style={{ margin: 0 }}>
                    <Logo />
                </h1>
                {withMenu && <MenuWrapper >
                    <Menu menuItems={menuItems} />
                </MenuWrapper>}
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "12px 0 10px 0"
                    }}
                >{me
                    ? <UserDropdown me={me} />
                    : <>
                        <Text.Small style={{ cursor: "pointer" }} onClick={() => {
                            trackEvent("login_header")
                            setInitialIsSignup(false)
                            setIsLoginModalOn(true)
                        }
                        }>Увійти</Text.Small>
                    </>}


                </div>
                {isLoginModalOn
                    ? <LoginModal setIsLoginModalOn={setIsLoginModalOn} initialIsSignup={initialIsSignup} />
                    : undefined
                }

            </HeaderStyled>
        </HeaderContainer>
    )
}

export const LoginModal = ({ setIsLoginModalOn, initialIsSignup }) => (
    <div style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        background: "rgba(0,0,0,.7)",
        zIndex: 12,
        top: 0,
        bottom: 0,
        left: 0,
        bottom: 0,
    }} onClick={() => setIsLoginModalOn(false)}>
        <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: 13,
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 50px)",
            padding: 28,
            maxWidth: "600px",
            background: "#fff",
            maxHeight: "calc(100% - 50px)",
            overflow: "auto"
        }} onClick={(e) => e.stopPropagation()}>
            <Star deg={45} style={{
                position: "absolute",
                top: 16,
                right: 16,
                cursor: "pointer",
            }}
                onClick={() => setIsLoginModalOn(false)}
            />
            <LoginSignup initialIsSignup={initialIsSignup} />

        </div>
    </div>
)

const MenuWrapper = styled.div`
    justify-self: center;
    @media(max-width: 768px) {
        flex: 0 0 100%;
        order: 2;
    }
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

`
const MenuContainer = styled.div`
    display: flex;
    justify-content: center;
    overflow-x: scroll;
    min-width: 424px;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
`
const MenuItem = styled.a`
    &:hover {
        border-bottom: 2px solid #000;
        padding: 22px 0 16px 0;
    }
    padding: 22px 0 18px 0;
    color: rgba(0,0,0, ${({ isActive }) => isActive ? "1" : ".5"});
    text-decoration: none;
    ${({ isActive }) => isActive ? "border-bottom: 2px solid #000;" : undefined});
    font-size: 14px;
    white-space: nowrap;
    margin-left: ${({last}) => last ? "0" : "16px"};    
`

const Menu = ({ menuItems }) => {
    const [activeItem, setActiveItem] = React.useState()
    return (
        <MenuContainer>
            {menuItems.map(([title, link], i) => (
                <MenuItem
                    href={link}
                    key={link}
                    onClick={() => setActiveItem(link)}
                    isActive={activeItem === link}
                    last={i===menuItems.length}
                >{title}</MenuItem>
            ))}
        </MenuContainer>
    )
}

const menuItems = [
    ["Про проєкт", "/#about"],
    ["Як отримати допомогу", "/#timeline"],
    ["Запитання-Відповді", "/#qna"],

]

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
